import { Link } from "gatsby"
import React, { useState, useReducer } from "react"
import { graphql } from "gatsby"
import ArrowBlue from "../assets/arrow-blue-left.svg"
import Layout from "../components/Layout/Layout"
import styles from "../styles/careersPage.module.scss"
import { SeeMoreButton } from "../components/seeMoreButton/seeMoreButton"
import WhiteSection from "../components/whiteSection/whiteSection"
import Loader from "react-loader-spinner"
import tt from "../helpers/translation"
import Slide from "react-reveal/Slide"

const initialState = {
  name: null,
  lastname: null,
  coverLetter: null,
  curriculum: null,
  acceptedPolicy: false,
  formResult: null,
  loading: false,
}

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

function reducer(state, action) {
  switch (action.type) {
    case "SET_FORM_VALUE":
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      }
    case "SEND_FORM":
      return {
        ...state,
        loading: true,
        formResult: null,
      }
    case "SEND_FORM_OK":
      return {
        ...initialState,
        formResult: action.payload,
      }
    case "SEND_FORM_FAILED":
      return {
        ...state,
        loading: false,
        formResult: action.payload,
      }
  }
}

const CareerPosition = ({ data, pageContext }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [acceptedPolicy, setPolicy] = useState(false)
  const disabled =
    state &&
    !(state.name && state.lastname && state.curriculum && acceptedPolicy)

  const { seoDataJson: seoData } = data
  const [resData] = data.allCareersFichaJson.edges.filter(
    ele => ele.node.name === pageContext.pageName
  )

  const jobIntroduction = () => {
    return resData.node.intro.map((element, index) => (
      <li key={index}>{element}</li>
    ))
  }

  const jobDescription = () => {
    return resData.node.jobDescription.map((element, index) => (
      <li key={index}>{element}</li>
    ))
  }

  const necessarySkills = () => {
    if (!resData.node.necessarySkills || !resData.node.necessarySkills.length) {
      return null
    }
    return resData.node.necessarySkills.map((element, index) => {
      if (
        element === "ReactJS / Redux" ||
        element === "NodeJS / Typescript" ||
        element === "PHP (Laravel / Symphony)"
      ) {
        return (
          <li className={styles.description_subbullet} key={index}>
            {element}
          </li>
        )
      } else {
        return <li key={index}>{element}</li>
      }
    })
  }

  const greatlyValued = () => {
    return resData.node.greatlyValued.map((element, index) => {
      if (
        element ===
        tt(
          "It is not essential that you know all these technologies, much less, but the more of them you have experience, the better!",
          pageContext.lang
        )
      ) {
        return (
          <li className={styles.description_no_list_no_margin} key={index}>
            {element}
          </li>
        )
      } else {
        return <li key={index}>{element}</li>
      }
    })
  }

  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === resData.node.name
  })

  const previousPage = () => {
    if (pageContext.lang === "ca") {
      return "/ca/ofertes-de-feina/"
    }
    if (pageContext.lang === "es") {
      return "/es/ofertas-de-empleo/"
    } else {
      return "/careers/"
    }
  }

  const onInputChange = e => {
    e.preventDefault()
    dispatch({
      type: "SET_FORM_VALUE",
      payload: { field: e.target.name, value: e.target.value },
    })
  }

  const onFilesChange = async e => {
    e.persist()
    const file = e.target.files[0]
    const base64 = await toBase64(file)
    dispatch({
      type: "SET_FORM_VALUE",
      payload: { field: e.target.name, value: base64 },
    })
  }

  const onSubmit = e => {
    e.preventDefault()
    dispatch({ type: "SEND_FORM" })

    fetch(`${process.env.GATSBY_FORM_ENDPOINT}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        origin: "careers-form",
        name: state.name,
        lastName: state.lastname,
        position: resData.node.openPosition,
        coverLetter: state.coverLetter,
        curriculum: state.curriculum,
      }),
    })
      .then(res => {
        if (res.status !== 200) {
          throw new Error("FORM_FAILED")
        }
        dispatch({
          type: "SEND_FORM_OK",
          payload: {
            message: tt(
              "Thank you for submitting your application, we will be in touch soon!",
              pageContext.lang
            ),
            type: "success",
          },
        })
      })
      .catch(err => {
        dispatch({
          type: "SEND_FORM_FAILED",
          payload: {
            message: tt(
              "There was an error submitting your form, please try again later",
              pageContext.lang
            ),
            type: "error",
          },
        })
      })
  }

  return (
    <Layout
      leftContactUs
      lan={pageContext.lang}
      location="careers"
      urls={resultUrls}
      contrast={true}
      seoData={seoData}
    >
      <WhiteSection
        careersClass
        firstTitle={tt("Open position", pageContext.lang)}
        subtitle={resData.node.openPosition}
      >
        <div className={styles.white_section_container}>
          <div className={styles.text_container}>
            <div className={styles.title_with_button}>
              <h3>{tt("Description", pageContext.lang)}</h3>
              <Link
                to={previousPage()}
                className={`${styles.button} ${styles.back}`}
              >
                <ArrowBlue />
                <span>{tt("Back", pageContext.lang)}</span>
              </Link>
            </div>
            <div className={styles.h2_p_container}>
              <div>
                <ul
                  className={`${styles.description} ${styles.description_no_list}`}
                >
                  {jobIntroduction()}
                </ul>
              </div>
            </div>
            <h4>{tt("Top skills required are:", pageContext.lang)}</h4>
            <div className={styles.h2_p_container}>
              <div>
                <ul className={styles.description}>{jobDescription()}</ul>
              </div>
            </div>
            {!!necessarySkills() && (
              <>
                <h4>
                  {tt("The necessary technical knowledge:", pageContext.lang)}
                </h4>
                <div className={styles.h2_p_container}>
                  <div>
                    <ul className={styles.description}>{necessarySkills()}</ul>
                  </div>
                </div>
              </>
            )}
            <h4>{tt("Greatly valued:", pageContext.lang)}</h4>
            <div className={styles.h2_p_container}>
              <div>
                <ul className={styles.description}>{greatlyValued()}</ul>
              </div>
            </div>
            <h4>{tt("Benefits:", pageContext.lang)}</h4>
            <div className={styles.h2_p_container}>
              <div>
                <ul className={styles.description}>
                  <li>{tt("7-hour intensive day.", pageContext.lang)}</li>
                  <li>
                    {tt(
                      "Participation in the technological and business decision making.",
                      pageContext.lang
                    )}
                  </li>
                  <li>
                    {tt("Direct contact with the CEO.", pageContext.lang)}
                  </li>
                  <li>
                    {tt(
                      "Flexible start schedule between (8:00 - 9:00).",
                      pageContext.lang
                    )}
                  </li>
                  <li>{tt("Full remote.", pageContext.lang)}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </WhiteSection>
      <div className={`${styles.blue_section_container}`}>
        <Slide left>
          <div className={styles.text_container}>
            <h2>{tt("Apply for this role!", pageContext.lang)}</h2>
            <p>
              {tt(
                "To apply for this opportunity, please fill the form and attach your resume and a cover letter.",
                pageContext.lang
              )}
            </p>
            <form className={styles.form_container} onSubmit={e => onSubmit(e)}>
              <div className={styles.input_container}>
                <p>{tt("Name:", pageContext.lang)}</p>
                <input name="name" onChange={onInputChange} />
              </div>
              <div className={styles.input_container}>
                <p>{tt("Last Name:", pageContext.lang)}</p>
                <input name="lastname" onChange={onInputChange} />
              </div>
              <div className={styles.input_container}>
                <p>{tt("Job Position:", pageContext.lang)}</p>
                <input
                  name="position"
                  value={resData.node.openPosition}
                  disabled
                />
              </div>
              <div className={styles.file_input_container}>
                <div>
                  <p>{tt("Curriculum:", pageContext.lang)}</p>
                  <input
                    name="curriculum"
                    type="file"
                    onChange={onFilesChange}
                    placeholder="Attach your CV"
                  />
                </div>
              </div>
              <div className={styles.file_input_container}>
                <div>
                  <p>{tt("Cover Letter:", pageContext.lang)}</p>
                  <input
                    name="coverLetter"
                    type="file"
                    onChange={onFilesChange}
                  />
                </div>
              </div>
              <input
                id="privacy-policy"
                onChange={() => setPolicy(!acceptedPolicy)}
                type="checkbox"
              />
              <label htmlFor="privacy-policy">
                {" "}
                {tt("I have read and accepted the ", pageContext.lang)}
                <a href="">
                  {" "}
                  {tt("privacy and data protection policy.", pageContext.lang)}
                </a>
              </label>
              <div style={{ marginTop: "15px" }}>
                <SeeMoreButton
                  text={tt("Send Form", pageContext.lang)}
                  type="submit"
                  disabled={disabled}
                />
              </div>
              {disabled ? null : state.loading ? (
                <Loader
                  className={styles.loader}
                  type="ThreeDots"
                  color="white"
                  height={20}
                  width={50}
                />
              ) : null}
              {state.formResult && (
                <p
                  className={
                    state.formResult.type === "error"
                      ? styles.form_result_fail
                      : styles.form_result_success
                  }
                >
                  {state.formResult.message}
                </p>
              )}
            </form>
          </div>
        </Slide>
      </div>
    </Layout>
  )
}

export const pageQuery = () => graphql`
  query careerQuery($lang: String!, $pageName: String!) {
    seoDataJson(lang: { eq: $lang }, name: { eq: $pageName }) {
      url
      title
      description
      alternateLanguage
      alternateUrl
    }
    allCareersFichaJson(
      filter: { lang: { eq: $lang }, name: { eq: $pageName } }
    ) {
      edges {
        node {
          name
          openPosition
          intro
          jobDescription
          necessarySkills
          greatlyValued
          lang
        }
      }
    }
  }
`

export default CareerPosition
